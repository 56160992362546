module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"description","store":true,"attributes":{"boost":5}},{"name":"shortDescription","store":true,"attributes":{"boost":5}},{"name":"content"},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{}},"filename":"search_index.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portal Puntano 4.0","short_name":"Portal Puntano 4.0","description":"Portal Puntano es el portal de trámites online de la Provincia de San Luis","lang":"es","display":"standalone","icon":"src/assets/images/icon.png","start_url":"/","background_color":"#663399","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c7996e7756f62a9690748b1cd74d2f01"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"autoGenHomeLabel":"Inicio","useAutoGen":true,"exclude":["/categoryProcedure"],"crumbLabelUpdates":[{"pathname":"/categoryProcedure/certificate","crumbLabel":"Certificados y Constancias"},{"pathname":"/categoryProcedure/others","crumbLabel":"Otros"},{"pathname":"/categoryProcedure/denouncementProcedures","crumbLabel":"Reclamos y Denuncias"},{"pathname":"/categoryProcedure/familyProcedures","crumbLabel":"Familia"},{"pathname":"/categoryProcedure/waterProcedures","crumbLabel":"Agua"},{"pathname":"/categoryProcedure/healthProfessionals","crumbLabel":"Profesionales de la salud"},{"pathname":"/categoryProcedure/stateEmployees","crumbLabel":"Empleados del Estado"},{"pathname":"/categoryProcedure/judicialProcedures","crumbLabel":"Personeria Jurídica"},{"pathname":"/categoryProcedure/RPI","crumbLabel":"Registro de la Propiedad Inmueble"},{"pathname":"/technicalSupport","crumbLabel":"Soporte Técnico"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
